import TaskList from '@/components/Assistants/TaskList/TaskList.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'Tasks',
  components: {
    TaskList,
    HeaderTopDashboard,
  },

  data () {
    return {
    }
  },
  methods: {

  },
  created () {
  },
  mounted () {
  },
}
