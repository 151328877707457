export class Task {
    static KNOWLEDGE_DROPPED = 1
    static LACK_OF_INFORMATION = 2
    static INCORRECT_RESPONSE = 3
    static VALIDATE_RESPONSE_BY_AN_AGENT = 4

    static TASK_STATUS_PENDING = 0;
    static TASK_STATUS_COMPLETE = 1;

    static TASK_CHANNEL_WHATSAPP = 1;
    static TASK_CHANNEL_WEBCHAT = 2;
}
